<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close width="20%" v-model="dialogTableVisible" title="分配">
    <div>
      <el-form :model="form">
        <el-form-item label="负责人" :label-width="formLabelWidth">
          <el-select v-model="userIds1" placeholder="选择人员" multiple collapse-tags style="width: 80%;" size="small" filterable>
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.CnName"
              :value="item.id" size="small">
            </el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="录入" :label-width="formLabelWidth">
          <el-select v-model="userIds2" placeholder="选择人员" multiple collapse-tags style="width: 80%;" size="small" filterable>
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.CnName"
              :value="item.id" size="small">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="记账" :label-width="formLabelWidth">
          <el-select v-model="userIds3" placeholder="选择人员" multiple collapse-tags style="width: 80%;" size="small" filterable>
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.CnName"
              :value="item.id" size="small">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="报税" :label-width="formLabelWidth">
          <el-select v-model="userIds4" placeholder="选择人员" multiple collapse-tags style="width: 80%;" size="small" filterable>
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.CnName"
              :value="item.id" size="small">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="业务负责人" :label-width="formLabelWidth">
          <el-select v-model="userIds5" placeholder="选择人员" multiple collapse-tags style="width: 80%;" size="small" filterable>
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.CnName"
              :value="item.id" size="small">
            </el-option>
          </el-select>
        </el-form-item> -->
       
      </el-form>
      
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button size="small" @click="dialogTableVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="save()">确定</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { findPeople } from '@/api/base'
import { assignCustomers ,assignCustomersList } from '@/api/company'
export default {
  props: {
    code: '',
  },
  data(){
    return {
      formLabelWidth: "100px",
      dialogTableVisible: false,
      options: [],
      userIds1: [],
      // userIds2: [],
      // userIds3: [],
      // userIds4: [],
      // userIds5: [],
    }
  },
  methods: {
    init(comIds,row){
      if(comIds && comIds.length != 0){
        this.comIds = comIds
        findPeople({code:this.code}).then(res => {
          this.options = res.data.data.list
          this.dialogTableVisible = true
        })
        this.userIds1 = []
        // this.userIds2 = []
        // this.userIds3 = []
        // this.userIds4 = []
        // this.userIds5 = []
        if(comIds.length == 1)
        assignCustomersList({comId:row.id}).then(res=>{
          let arr = res.data.data.list[0].userIds.split(",")
          // let arr1 = res.data.data.list[1].userIds.split(",")
          // let arr2 = res.data.data.list[2].userIds.split(",")
          // let arr3 = res.data.data.list[3].userIds.split(",")
          // let arr4 = res.data.data.list[4].userIds.split(",")
          this.userIds1 = arr.map(v=>{
            return v*1
          })
          // this.userIds2 = arr1.map(v=>{
          //   return v*1
          // })
          // this.userIds3 = arr2.map(v=>{
          //   return v*1
          // })
          // this.userIds4 = arr3.map(v=>{
          //   return v*1
          // })
          // this.userIds5 = arr4.map(v=>{
          //   return v*1
          // })
          console.log(this.userIds1);
        })
      }else{
        this.$qzfMessage("请选择公司", 1)
      }
    },
    save(){
      if(this.userIds1.length == 0){
        this.$qzfMessage("请选择责任人", 1)
        return
      }
      // if(this.userIds2.length == 0){
      //   this.$qzfMessage("请选择录入人", 1)
      //   return
      // }
      // if(this.userIds3.length == 0){
      //   this.$qzfMessage("请选择记账人", 1)
      //   return
      // }
      // if(this.userIds4.length == 0){
      //   this.$qzfMessage("请选择报税人", 1)
      //   return
      // }
      // if(this.userIds5.length == 0){
      //   this.$qzfMessage("请选择负责人", 1)
      //   return
      // }
      let parma = this.comIds.map(v=>{
        let arr = []
        this.userIds1.map(u=>{
          arr.push({
            userId: u,
            userType: "1",
          })
        })
        // this.userIds2.map(u=>{
        //   arr.push({
        //     userId: u,
        //     userType: "2",
        //   })
        // })
        // this.userIds3.map(u=>{
        //   arr.push({
        //     userId: u,
        //     userType: "3",
        //   })
        // })
        // this.userIds4.map(u=>{
        //   arr.push({
        //     userId: u,
        //     userType: "4",
        //   })
        // })
        // this.userIds5.map(u=>{
        //   arr.push({
        //     userId: u,
        //     userType: "5",
        //   })
        // })
        return {
          comId: v * 1,
          userItems: arr,
        }
      })

      // let parmas = {
      //   companys: parma
      // }
      assignCustomers(parma).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("客户分配成功")
          this.dialogTableVisible = false
          this.$emit("success")
        }
      })
      
    },
  }
}
</script>
<style scoped>

</style>
