<template>
  <!-- <el-button @click="handleCreate()" size="small" type="success">新增</el-button> -->
  <el-dialog :close-on-click-modal="false" destroy-on-close title="新增客户" v-model="dialogFormVisible" width="60%">
    <el-form ref="dataForm" :rules="rules" :model="temp" label-position="right" label-width="170px" :inline="true">
      <div class="common_dialog_box clearfix">
        <div class="common_title">企业基本信息</div>
        <div class="item_one clearfix">
        <el-form-item label="企业名称:" prop="name" class="item">
          <el-autocomplete style="width:100%;" size="small" v-model="temp.name" valueKey="ghdwmc" :fetch-suggestions="querySearch" @select="handleSelect" placeholder="请输入内容"></el-autocomplete>
        </el-form-item>
        <el-form-item label="所属税务总局:" prop="districtCode" class="item">
          <selectcity v-model:citys="temp.districtCode" @success="discodeChange()"></selectcity>
        </el-form-item>
        <el-form-item label="纳税人资格:" prop="type" class="item">
          <selecttaxtype v-model:type="temp.type"></selecttaxtype>
        </el-form-item>
        </div>
      </div>
      <div class="common_dialog_box clearfix">
        <div class="common_title">
          账套信息
          <span>温馨提示：请正确选择账套的启用期间和会计制度，否则启用账套之后无法进行修改，只能重置账套！</span>
        </div>
        <div class="item_one clearfix">
          <el-form-item label="企业会计制度:" prop="accountSystem" class="item">
            <el-select v-model="temp.accountSystem" placeholder="请选择会计准则" size="small" style="width:100%;">
            <el-option label="小企业会计准则2013版" value="小企业会计准则2013版" size="small">
            </el-option>
            <el-option label="一般企业会计准则(未执行新金融准则)" value="一般企业会计准则" size="small">
            </el-option>
            <el-option label="一般企业会计准则(已执行新金融准则)" value="一般企业会计准则(已执行新金融准则)" size="small">
            </el-option>
            <el-option label="企业会计制度"  value="企业会计制度" size="small">
            </el-option>
            <el-option label="民间非盈利" value="民办非" size="small"></el-option>

          </el-select>

            
          </el-form-item>
          <el-form-item label="公司类型:" prop="companyType" class="item">
            <el-select v-model="temp.companyType" placeholder="请选择公司类型" size="small" style="width:100%;" class="filter-item-s">
              <el-option label="有限责任公司" value="有限责任公司" size="small">
              </el-option>
              <el-option label="个体工商户" value="个体工商户" size="small">
              </el-option>
              <el-option label="个人独资企业" value="个人独资企业" size="small">
              </el-option>
              <el-option label="合伙企业" value="合伙企业" size="small">
              </el-option>
              <el-option label="农村合作社" value="农村合作社" size="small">
              </el-option>
              <el-option label="民间非盈利" value="民办非" size="small"></el-option>

            </el-select>
          </el-form-item>
          <el-form-item label="账套启用时间:" prop="startAccountPeriod" class="item">
            <el-date-picker :picker-options="pickerOptions" v-model="temp.startAccountPeriod" type="month" placeholder="启用月" style="width:100%;" size="small"></el-date-picker>
          </el-form-item>
        </div>
      </div>
      <div class="common_dialog_box clearfix">
        <div class="common_title">国税登录密码</div>
        <div class="item_one clearfix">
          <el-form-item label="纳税人识别编号:" prop="taxNo" class="item">
            <el-input v-model="temp.taxNo" style="width:100%;" size="small" type="text" />
          </el-form-item>
          <el-form-item label="登录方式:" prop="loginMethod" class="item">
            <el-select v-model="temp.loginMethod" placeholder="请选择登录方式" style="width:100%;" size="small" @change="changeLoginMethod" :disabled="!userPass.loginMethodDisabled">
              <template v-if="userPass.loginMethodOption">
                <el-option v-for="item in userPass.loginMethodOption" :key="item" :label="item" :value="item"></el-option>
              </template>
              <template v-else>
                <el-option v-for="item in state_tax_mode" :key="item.value" :label="item.value" :value="item.value"></el-option>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item v-if="userPass.idNo" :label="userPass.idNoLabel" prop="idNo" class="item">
            <el-input v-model="temp.idNo" style="width:100%;" :placeholder="userPass.idNoText" size="small" />
          </el-form-item>
          <el-form-item v-if="userPass.password" :label="userPass.passwordLabel" prop="password" class="item">
            <el-input v-model="temp.password" style="width:100%;" :placeholder="userPass.passwordText" size="small" type="text" />
          </el-form-item>
          <el-form-item v-if="userPass.zzrxm" label="办税人/自然人姓名:" prop="zzrxm" class="item">
            <el-input v-model="temp.zzrxm" style="width:100%;" size="small" :placeholder="userPass.zzrxmText" type="text" />
          </el-form-item>
          <el-form-item v-if="userPass.zzridno" label="办税人/自然人身份证号:" prop="zzridno" class="item">
            <el-input v-model="temp.zzridno" style="width:100%;" size="small" :placeholder="userPass.zzridnoText" type="text" />
          </el-form-item>




          <el-form-item v-if="userPass.zzrmn" :label="userPass.zzrmnLabel" prop="zzrmn" class="item">
            <el-input v-model="temp.zzrmn" style="width:100%;" size="small" :placeholder="userPass.zzrmnText" type="text" />
          </el-form-item>





          <el-form-item   label="个税登录方式:" prop="personalLoginType" class="item"> 
            <el-select v-model="temp.personalLoginType" placeholder="请选择个税登录方式" style="width:100%;" @change="changePersonalType" size="small">
              <el-option v-for="item in personal_type" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="temp.personalLoginType == '实名登录'" label="个税实名账号:" prop="personalIdno" class="item">
            <el-input v-model="temp.personalIdno" style="width:100%;" type="text" size="small" />
          </el-form-item>
          <el-form-item label="个税申报密码:" prop="personalTaxPwd" class="item">
            <el-input v-model="temp.personalTaxPwd" style="width:100%;" type="text" size="small" />
          </el-form-item>
          <el-form-item v-if="userPass.xzsf" label="选择身份:" prop="xzsf" class="item">
            <el-select v-model="temp.xzsf" placeholder="选择身份" style="width:100%;" size="small">
              <el-option label="法人" value="法人"></el-option>
              <el-option label="财务负责人" value="财务负责人"></el-option>
              <el-option label="办税人" value="办税人"></el-option>
              <el-option label="购票员" value="购票员"></el-option>
              <el-option label="其他人员" value="其他人员"></el-option>
              <el-option label="附办税人" value="附办税人"></el-option>
              <el-option label="普通管理员" value="普通管理员"></el-option>
              <el-option label="实名办税" value="实名办税"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="userPass.jbr" label="经办人:" prop="jbr" class="item">
            <input type="password" style="display: none"/>
            <el-input v-model="temp.jbr" style="width:100%;" size="small" type="text" />
          </el-form-item>
          <el-form-item v-if="userPass.jbrIdno" label="经办人身份证号:" prop="jbrIdno" class="item">
              <input type="password" style="display: none"/> 
            <el-input v-model="temp.jbrIdno" style="width:100%;" size="small" type="text" />
          </el-form-item>
        </div>
      </div>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
      <el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
      <el-button size="small" type="primary" @click="createData()">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogTableVisible" title="提示">
    <el-table :data="failList" border>
      <template #empty>
        <el-empty :image-size="160" description="没有数据"></el-empty>
      </template>
      <el-table-column prop="name" label="公司名称" min-width="180" >
        <template #default="scope">
          {{scope.row.name}}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="失败原因" min-width="180" >
        <template #default="scope">
          {{scope.row.remark}}
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import { saveEaCompany} from "@/api/company"
import { disCodeUserPass , parseTime  } from "@/utils";
import { collectionCorp  } from "@/api/login.js";

import selectcity from "@/components/Screening/selectcity";
import selecttaxtype from "@/components/Screening/selecttaxtype";
export default {
  name:'addCustomers',
  components:{
    selectcity,
    selecttaxtype
  },
  data() {
    return {
      pickerOptions:{
        disabledDate(time) {
          // return  new Date('2020-03').getTime() > time.getTime() > new Date('2020-08').getTime()  //new Date(String(store.getters.endPeriod.substring(0, 4)) + '-' + store.getters.endPeriod.substring(4, 6)).getTime() < 
          return time.getTime() > new Date();
        },
      },
      dialogFormVisible: false,
      restaurants: [],
      temp: {
        name: "",
        districtCode: [],
        districtName:'',
        regDate: "",
        taxNo: "",
        type: "",
        accountSystem: "",
        loginMethod: "证件登录",
        personalLoginType: "",
        personalIdno: "",
        personalTaxPwd: "",
        startAccountPeriod:'',
        password: "",
        xzsf:'',
        idNo: "",
        idType: "居民身份证",
        jbr: '',
        jbrIdno: '',
        zzridno:'',
        zzrmn:'',
        zzrxm:'',
        companyType:'有限责任公司'
      },
      userPass: {
        loginMethod: "",
        personalIdno:false
      },
      state_tax_mode: [],
      personal_type:[
        {
          value:'实名登录',
          label:'实名登录'
        },
        {
          value:'申报密码登录',
          label:'申报密码登录'
        }
      ],
      city:[],
      rules: {
        name: [
          { required: true, message: "公司名不能为空", trigger: "change" }
        ],
        districtCode: [
          { required: true, message: "所属税务总局不能为空", trigger: "change" }
        ],
        startAccountPeriod: [
          { required: true, message: "请选择账号启用时间", trigger: "change" }
        ],
        taxNo: [
          { required: true, message: "请输入纳税人识别编号", trigger: "change" }
        ],
        type: [
          { required: true, message: "请选择纳税人资格", trigger: "change" }
        ],
        accountSystem: [
          { required: true, message: "请选择企业会计制度", trigger: "change" }
        ],
        companyType: [
          { required: true, message: "请选择企业类型", trigger: "change" }
        ],
        loginMethod: [
          { required: true, message: "请选择国税登录方式", trigger: "change" }
        ],
        personalLoginType: [
          { required: true, message: "请选择个税登录方式", trigger: "change" }
        ],
        personalIdno: [
          { required: true, message: "请输入个税实名账号", trigger: "change" }
        ],
        personalTaxPwd: [
          { required: true, message: "请输入个税申报密码", trigger: "change" }
        ],
        zzrmn: [
          { required: true, message: "请输入密码", trigger: "change" }
        ],
        zzridno: [
          { required: true, message: "请输入办税员的身份证号码", trigger: "change" }
        ],
        zzrxm: [
          { required: true, message: "请输入办税员姓名", trigger: "change" }
        ],
        password: [
          { required: true, message: "请输入国税登录密码", trigger: "change" }
        ],
        xzsf: [
          { required: true, message: "请选择身份", trigger: "change" }
        ],
        idNo: [
          { required: true, message: "请输入正确的证件号码", trigger: "change" }
        ],
        idType: [
          { required: true, message: "请选择证件类型", trigger: "change" }
        ],
        jbrIdno: [
          { required: true, message: "请输入经办人身份证号", trigger: "change" }
        ],
        jbr: [
          { required: true, message: "请输入经办人", trigger: "change" }
        ]
      },
      dialogTableVisible:false,
      failList:[],
    }
  },
  // created() {
  //   this.getListJY()
  // },
  methods:{
    // 选择个税登录方式时
    changePersonalType(){
      if (this.temp.personalLoginType == "实名登录") {
          this.userPass.personalIdno = true;
      }else  {
          this.userPass.personalIdno = false;
      }
    },
    // 选择地区时
    discodeChange(){
      //地区
      if(!this.temp.districtCode){
        return
      }
      this.temp.loginMethod = ''
      this.userPass = disCodeUserPass(this.temp.districtCode,this.temp.loginMethod)
      this.temp.loginMethod = this.userPass.loginMethod
      if (this.temp.personalLoginType == "实名登录") {
          this.userPass.personalIdno = true;
      }else  {
          this.userPass.personalIdno = false;
      }
    },

    querySearch(queryString, cb) {
      if(queryString == ""){
        return
      }
      collectionCorp({name:queryString}).then(res=>{
        if(res.data.msg == "success"){
          let results = res.data.data.list
          if(results){
            cb(results);
          }
         
        }
      })
      
    },
    handleSelect(item) {
      this.temp.taxNo = item.ghdwsbh
    },
    // resetTemp() {
    //   this.temp.zzrxm = ''
    //   this.temp.idNo = ''
    //   this.temp.password = ''
    //   this.temp.zzridno = ''
    //   this.temp.zzrmn = ''
    // },
    resetTemp1() {
      this.temp = {
        name: "",
        districtCode: [],
        districtName:'',
        regDate: "",
        taxNo: "",
        type: "",
        accountSystem: "",
        loginMethod: this.userPass.loginMethod,
        personalLoginType: "",
        personalIdno: "",
        personalTaxPwd: "",
        startAccountPeriod:'',
        password: "",
        xzsf:'',
        idNo: "",
        idType: "居民身份证",
        jbr: '',
        jbrIdno: '',
        zzridno:'',
        zzrmn:'',
        zzrxm:'',
        companyType:'有限责任公司'
      };
    },
    // 初始化数据
    init(){
    },
    handleCreate() {
      this.resetTemp1();
      this.userPass = {
        loginMethod: "",
      }
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
      });
    },
    // 新增确定时
    createData() {
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          const temp = {
            name: this.temp.name,
            districtCode: this.temp.districtCode,
            // disCodes:this.temp.districtCode,
            regDate: this.temp.regDate,
            taxNo: this.temp.taxNo,
            type: this.temp.type,
            accountSystem: this.temp.accountSystem,
            companyType:this.temp.companyType,
            loginMethod: this.temp.loginMethod,
            personalLoginType: this.temp.personalLoginType,
            personalTaxPwd: this.temp.personalTaxPwd,
            zzrmn: this.temp.zzrmn,
            zzrxm: this.temp.zzrxm,
            zzridno: this.temp.zzridno,
            password: this.temp.password,
            idNo: this.temp.idNo,
            idType: this.temp.idType,
            startAccountPeriod: parseTime(this.temp.startAccountPeriod,"{y}{m}"),
            jbr: this.temp.jbr,
            jbrIdno: this.temp.jbrIdno,
            xzsf: this.temp.xzsf,
          };
          saveEaCompany({list:[temp]}).then(res => {
            if(res.data.data.success_total == 0){
              this.$qzfMessage("创建失败",2) 
              this.failList = res.data.data.fail_list
              this.dialogTableVisible = true
            }else{
              this.dialogFormVisible = false;
              this.$qzfMessage("创建成功") 
              this.$emit('success')
            }
          });
        }else{
          console.log("校验失败");
        }
      });
    },
    changeLoginMethod(){
      this.userPass = disCodeUserPass(this.temp.districtCode,this.temp.loginMethod)
    },

  }
}
</script>

<style lang="scss" scoped>
.common_dialog_box{
  width: 100%;
  margin: 0 auto;
}
.common_dialog_box .common_title{
  font-size: 14px;
  color: black;
  font-weight: bold;
  line-height: 36px;
  border-bottom: 1px solid #c2c2c2;
  margin-bottom: 10px;
}
.common_dialog_box .common_title span{
  color: red;
  margin-left: 30px;
  font-weight: normal !important;
}
.common_dialog_box  .item{
  // width: 46%;
  // margin-bottom:20px
}
.common_dialog_box  .item:nth-child(2n+1){
  margin-left: 0;
}
.new_dialog .el-button--primary{
  background: $color-main;
  border-color: $color-main;
}
.common_dialog_box .el-select{
  margin:0 0 0 0 !important;
  width:100%
}
.common_dialog_box {
  :deep(.el-input__wrapper) {
    width:200px
  }
}
</style>