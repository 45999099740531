<template>
  <!-- 回收站彻底删除,单拿出来，要不样式有问题 -->
  <div class="right_box1">
    <el-button size="small" type="danger" @click="delAll" v-if="this.tabName == 'fifth' && this.isAdmin == 1">
      <el-icon><Delete /></el-icon> <span  >批量彻底删除</span>
    </el-button>
  </div>
  <div class="right_box">
    <qzf-video v-if="tabName == 'first'" vid="4467be5374fbe418fe59a2e763b49d37_4"></qzf-video>
    <qzf-video v-if="tabName == 'seventh'|| tabName == 'sixth'" vid="4467be5374c7b6d07c9fe43862da5c6a_4"></qzf-video>

    <el-button size="small" type="primary" @click="customerExport"    v-if="tabName == 'seventh'|| tabName == 'sixth'">
      <el-icon><FolderOpened /></el-icon><span  > 导出</span>
    </el-button>

    <el-button size="small" type="primary" @click="downLoadPack" v-if="this.userRoleName != '学生' && (tabName == 'seventh'|| tabName == 'sixth')">
      <el-icon><Pointer /></el-icon><span  > 下载数据包</span>
    </el-button>

    <el-button size="small" type="primary" @click="receivePackge" v-if="this.userRoleName != '老师' && (tabName == 'seventh'|| tabName == 'sixth')">
      <el-icon><Pointer /></el-icon><span  >领取教学包</span>
    </el-button>

    <el-dropdown v-if="tabName == 'first'" split-button type="success" @click="addCustomerCom" size="small" style="margin-right:10px" :hide-on-click="false">
      <i class="iconfont icon-jiahao"></i> 新增客户
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item>
            <span style="width:100px;display: inline-block;text-align: center;" @click="daoruCustomer">导入客户</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <!-- <el-button size="small" type="danger" @click="goRecycles" v-if="this.tabName == 'first'">批量删除</el-button> -->
    <!-- <span class="right_box1"><el-button size="small" type="danger" @click="delAll" v-if="this.tabName == 'first'">批量彻底删除</el-button></span> -->
    <el-button size="small" type="danger" @click="delAll" v-if="this.tabName == 'first' && this.isAdmin == 1"   >
      <el-icon><Delete /></el-icon> <span  >彻底删除</span>
    </el-button>
    <!-- <el-button size="small" type="primary" v-if="tabName == 'first'" @click="gather"   >
      <el-icon><Aim /></el-icon> <span  >采集</span>
    </el-button> -->
    <!-- <el-button size="small" type="primary" plain v-if="tabName == 'first'" @click="skipCollection" :loading="skipLoading">跳过采集</el-button> -->
    <el-dropdown size="small" style="margin-left:10px;margin-right:10px" :hide-on-click="false" v-if="tabName == 'first'">
      <el-button size="small" type="primary">
        更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
      </el-button>
      <template #dropdown>
        <el-dropdown-menu style="text-align:center">
          <!-- <el-dropdown-item command="a">
            <span @click="handleCommand()">采集科目余额表</span>
          </el-dropdown-item> -->
          <el-dropdown-item command="b">
            <span @click="skipCollection">跳过采集</span>
            <!-- <el-button size="small" type="primary" @click="skipCollection" :loading="skipLoading">跳过采集</el-button> -->
          </el-dropdown-item>
          <!-- <el-dropdown-item command="c">
            <span @click="handleMigrate()">账套迁移</span>
          </el-dropdown-item>

          <el-dropdown-item command="d">
            <span @click="cancelTask()">撤销采集</span>
          </el-dropdown-item> -->
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
  <el-table stripe :data="list" :height="contentStyleObj" style="width: 100%" border @selection-change="handleSelectionChange" v-loading="loading"  @sort-change="sortChange">
    <template #empty>
      <el-empty :image-size="150" description="没有数据"></el-empty>
    </template>
    <el-table-column align="center" prop="id" type="selection" min-width="55" />
    <!-- <el-table-column align="center" type="index" label="序号" width="55"  fixed="left" /> -->
    <el-table-column sortable="custom" label="编号" align="center" prop="sort" width="80" fixed="left" v-if="this.tabName == 'first' || this.tabName == 'seventh' || this.tabName == 'sixth'">
      <template #default="scope">
        <span @click="saveSort(scope.row)" style="color: orange;cursor: pointer;">{{ sortNumber(scope.row.sortCom)}}</span>  
      </template>
    </el-table-column>
    <el-table-column prop="name" fixed="left" label="公司名称" min-width="280" >
      <template #default="scope">
        <span v-if="scope.row.type == '1'" class="xgmStyle">小</span>
        <span v-if="scope.row.type == '2'" class="ybStyle">般</span>
        <span @click="$goEtax(scope.row.id)" style="cursor:pointer" type="text" v-if=" this.tabName == 'seventh' || this.tabName == 'sixth' || this.tabName == 'eighth'">{{ scope.row.name }}</span>
        <span v-else>{{ scope.row.name }}</span>
      </template>
    </el-table-column>
    <el-table-column align="center" prop="userName" label="账套归属" min-width="130">
      <template #default="scope">
        <span>{{ scope.row.userName }}</span>
      </template>
    </el-table-column>
    <el-table-column align="center" prop="startAccountPeriod" label="启用账期" min-width="90">
      <template #default="scope">
        <span>{{ scope.row.startAccountPeriod }}</span>
      </template>
    </el-table-column>
    <el-table-column align="center" prop="CurPeriod" label="当前账期" min-width="90">
      <template #default="scope">
        <span>{{ scope.row.CurPeriod }}</span>
      </template>
    </el-table-column>
    <!-- <el-table-column  v-if="changeF" prop="mobileRec" label="报税手机号" min-width="115">
      <template #default="scope">
        <span>{{ scope.row.mobileRec }}</span>
      </template>
    </el-table-column> -->
    <el-table-column prop="accountSystem" label="会计制度" min-width="200">
      <template #default="scope">
        <span>{{ scope.row.accountSystem }}</span>
      </template>
    </el-table-column>
    <el-table-column  prop="districtName" label="税局" min-width="85">
      <template #default="scope">
        <span>{{ scope.row.districtName }}</span>  
      </template>
    </el-table-column>
    <el-table-column  prop="districtName" label="负责人" min-width="180" v-if="this.tabName == 'seventh' || this.tabName == 'sixth'">
      <template #default="scope">
        <span v-if="scope.row.peoples">{{ scope.row.peoples.toString() }}</span>  
      </template>
    </el-table-column>
    <el-table-column  prop="districtName" label="停报时间" min-width="180" v-if="this.tabName == 'ninth'">
      <template #default="scope">
        <span>{{$parseTime(scope.row.updatedAt, "{y}-{m}-{d}")}}</span>  
      </template>
    </el-table-column>
    <el-table-column  prop="districtName" label="停报原因" min-width="180" v-if="this.tabName == 'ninth'">
      <template #default="scope">
        <span>{{ scope.row.remark }}</span>  
      </template>
    </el-table-column>
    <el-table-column  prop="manager" label="法人代表" min-width="100" v-if="this.tabName == 'first' || this.tabName == 'second' || this.tabName == 'third' || this.tabName == 'fourth' || this.tabName == 'fifth'">
      <template #default="scope">
        <span>{{ scope.row.manager }}</span>  
      </template>
    </el-table-column>
    <!-- <el-table-column align="center" prop="manager" label="财务责任人" min-width="80" v-if="this.tabName == 'sixth' || this.tabName == 'seventh' || this.tabName == 'eighth'">
      <template #default="scope">
        <span>{{ scope.row.manager }}</span>  
      </template>
    </el-table-column> -->
    <el-table-column align="left" prop="content" label="备注" min-width="120" v-if="this.tabName == 'sixth' || this.tabName == 'seventh' || this.tabName == 'eighth'">
      <template #default="scope">
        <el-popover v-model:visible="scope.row.visible" placement="bottom" :width="350" trigger="click" @after-leave="editNote1(scope.row)">
          <el-input
            v-model="noteContent"
            :rows="4"
            type="textarea"
            placeholder="请输入"
          />
          <div style="text-align: right; margin-top: 10px">
            <el-button size="small" type="primary" @click="scope.row.visible = false">取消</el-button>
            <el-button size="small" type="primary" @click="editNote(scope.row,2)">确定</el-button>
          </div>
          <template #reference>
            <span class="xgmStyle" @click="smallNotes(scope.row.id)">记</span>
          </template>
        </el-popover>
        <span>{{scope.row.content}}</span>
      </template>
    </el-table-column>
    <!-- <el-table-column  prop="districtName" label="建账采集状态" min-width="130" v-if="this.tabName == 'first'">
      <template #default="scope">
        <div class="item_icon">
          <p v-if="scope.row.cjjzStatus == '0'"><i class="iconfont icon-info"></i>{{$setUpStatusCj(scope.row.cjjzStatus)}}
          </p>
          <p v-if="scope.row.cjjzStatus == '1'"><i class="iconfont icon-info"></i>{{$setUpStatusCj(scope.row.cjjzStatus)}}
          </p>
          <p v-if="scope.row.cjjzStatus == '2'"><i class="iconfont icon-info"></i>{{$setUpStatusCj(scope.row.cjjzStatus)}}</p>
          <p v-if="scope.row.cjjzStatus == '3'"><i class="iconfont icon-duihao"></i>
          <el-tooltip class="item" effect="dark" :content="scope.row.cjjzLog" placement="top-start">
            <span size="small" class="sbcg" style="border: 0;">{{$setUpStatusCj(scope.row.cjjzStatus)}}</span>
          </el-tooltip>
          </p>
          <p v-if="scope.row.cjjzStatus == '4'"><i class="iconfont icon-duihao"></i>
          <el-tooltip class="item" effect="dark" :content="scope.row.cjjzLog" placement="top-start">
            <span size="small" style="border: 0;color:red">{{$setUpStatusCj(scope.row.cjjzStatus)}}</span>
          </el-tooltip>
          </p>
          <p v-if="scope.row.cjjzStatus == '5'"><i class="iconfont icon-info"></i>{{$setUpStatusCj(scope.row.cjjzStatus)}}</p>
          <p v-if="scope.row.cjjzStatus == '6'"><i class="iconfont icon-info"></i>{{$setUpStatusCj(scope.row.cjjzStatus)}}</p>
        </div>
      </template>
    </el-table-column> -->
    <el-table-column   label="税种采集状态" min-width="150" class-name="small_cell" v-if="this.tabName == 'first' || this.tabName == 'second' || this.tabName == 'third' || this.tabName == 'fourth' || this.tabName == 'fifth'">
      <template #default="scope">
        <!-- 采集 -->
        <el-tooltip placement="top" :disabled="scope.row.FailReason == ''">
          <template #content> {{scope.row.FailReason}} </template>
          <div class="item_icon" v-if="scope.row.setUpStatusCj == 6">
            <i class="iconfont icon-gantanhao"></i>
            <p>{{ setUpStatus1(scope.row.setUpStatusCj) }}</p>
          </div>
          <div class="item_icon" v-else-if="scope.row.setUpStatusCj == 1">
            <i class="iconfont icon-duihao"></i>
            <p>{{ setUpStatus1(scope.row.setUpStatusCj) }}</p>
          </div>
          <div class="item_icon" v-else-if="scope.row.setUpStatusCj == 2">
            <i class="iconfont icon-duihao"></i>
            <p>{{ setUpStatus1(scope.row.setUpStatusCj) }}</p>
          </div>
          <div class="item_icon" v-else-if="scope.row.setUpStatusCj == 3 || scope.row.setUpStatusCj == 9">
            <i class="iconfont icon-info"></i>
            <p>{{ setUpStatus1(scope.row.setUpStatusCj) }}</p>
          </div>
          <div class="item_icon" v-else-if="scope.row.setUpStatusCj == 4">
            <i class="iconfont icon-cuowu"></i>
            <p style="color:red">{{ setUpStatus1(scope.row.setUpStatusCj) }}</p>
          </div>
          <div class="item_icon" v-else-if="scope.row.setUpStatusCj == 5">
            <i class="iconfont icon-cuowu"></i>
            <p style="color:red">{{ setUpStatus1(scope.row.setUpStatusCj) }}</p>
          </div>
        </el-tooltip> 
      </template>
    </el-table-column>

    <el-table-column   label="税务初始化状态" min-width="150" class-name="small_cell" v-if="this.tabName == 'first' || this.tabName == 'second' || this.tabName == 'third' || this.tabName == 'fourth' || this.tabName == 'fifth'">
      <template #default="scope">
        <!-- 匹配 -->
        <el-tooltip placement="top" :disabled="scope.row.PpFailReason == ''">
          <template #content> 
            <div v-html="Tobreak(scope.row.PpFailReason)"></div>
          </template>
          <div class="item_icon" v-if="scope.row.setUpStatusCj == 3 || scope.row.setUpStatusCj == 9">
            <i class="iconfont icon-info"></i>
            <p>{{setUpStatus1(scope.row.setUpStatusCj)}}</p>
          </div>
          <div class="item_icon" v-else-if="scope.row.setUpStatusPp == 6">
            <i class="iconfont icon-gantanhao"></i>
            <p>{{ setUpStatus3(scope.row.setUpStatusPp) }}</p>
          </div>
          <div class="item_icon" v-else-if="scope.row.setUpStatusPp == 1">
            <i class="iconfont icon-duihao"></i>
            <p>{{ setUpStatus3(scope.row.setUpStatusPp) }}</p>
          </div>
          <div class="item_icon" v-else-if="scope.row.setUpStatusPp == 2">
            <i class="iconfont icon-duihao"></i>
            <p>{{ setUpStatus3(scope.row.setUpStatusPp) }}</p>
          </div>
          <div class="item_icon" v-else-if="scope.row.setUpStatusPp == 3">
            <i class="iconfont icon-info"></i>
            <p>{{ setUpStatus3(scope.row.setUpStatusPp) }}</p>
          </div>
          <div class="item_icon" v-else-if="scope.row.setUpStatusPp == 4">
            <i class="iconfont icon-cuowu"></i>
            <p style="color:red">{{ setUpStatus3(scope.row.setUpStatusPp) }}</p>
          </div>
        </el-tooltip>  
      </template>
    </el-table-column>

    
    <el-table-column  label="财务初始化状态" min-width="150" class-name="small_cell" v-if="this.tabName == 'first' || this.tabName == 'second' || this.tabName == 'third' || this.tabName == 'fourth' || this.tabName == 'fifth'">
      <template #default="scope">
        <!-- 建账 -->
        <el-tooltip placement="top" :disabled="scope.row.FailReason == ''">
          <template #content> {{scope.row.FailReason}} </template>
          <div class="item_icon" v-if="scope.row.setUpStatusJz == 6">
            <i class="iconfont icon-gantanhao"></i>
            <p>{{ setUpStatus2(scope.row.setUpStatusJz) }}</p>
          </div>
          <div class="item_icon" v-else-if="scope.row.setUpStatusJz == 1">
            <i class="iconfont icon-duihao"></i>
            <p>{{ setUpStatus2(scope.row.setUpStatusJz) }}</p>
          </div>
          <div class="item_icon" v-else-if="scope.row.setUpStatusJz == 2">
            <i class="iconfont icon-info"></i>
            <p>{{ setUpStatus2(scope.row.setUpStatusJz) }}</p>
          </div>
        </el-tooltip>
      </template>
    </el-table-column>
    
    <el-table-column align="center" prop="address" label="操作" min-width="480" v-if="this.tabName == 'first' || this.tabName == 'second' || this.tabName == 'third' || this.tabName == 'fourth' || this.tabName == 'fifth'">
      <template #default="scope">
        <el-button v-if="this.tabName != 'fifth'" @click="handleUpdate(scope.row)" size="small" type="primary" plain>
          <el-icon><Edit /></el-icon><span  > 编辑</span>
        </el-button>
        <el-button v-if="this.tabName != 'fifth'" size="small" @click="add_ea_subject_balance(scope.row)" plain type="warning">
          <el-icon><List /></el-icon><span  > 财务初始化</span>
        </el-button>
        <!-- <el-button v-if="this.tabName != 'fifth'" size="small" @click="companyfp(scope.row)" plain type="success">
          <el-icon><User /></el-icon><span  > 分配</span>
        </el-button> -->
        <!-- <el-button size="small" plain type="danger" v-if="this.tabName == 'first'" @click="goRecycle(scope.row)">删除</el-button> -->
        <el-button size="small" plain type="success" v-if="this.tabName == 'fifth'" @click="recycleRecover(scope.row)">
          <el-icon><Refresh /></el-icon><span  > 还原</span>
        </el-button>
        <el-button size="small" plain type="danger" v-if="this.tabName == 'fifth' && this.isAdmin == 1" @click="delCustomer(scope.row)">
          <el-icon><Delete /></el-icon> <span  >彻底删除</span>
        </el-button>
        <el-button size="small" plain type="primary" v-if="this.tabName == 'first'" @click="goBook(scope.row)">
          <el-icon><RefreshRight /></el-icon><span  > 转移到记账客户</span>
        </el-button>
      </template>
    </el-table-column>
    <el-table-column align="center" prop="address" label="操作" min-width="300" v-if="this.tabName == 'sixth' || this.tabName == 'seventh' || this.tabName == 'eighth'">
      <template #default="scope">
        <div v-if="scope.row.crmComId == 0">
          <el-button @click="$goEtax(scope.row.id)" size="small" type="primary" plain   >
            <el-icon><House /></el-icon><span  > 进入账簿</span>
          </el-button>
          <!-- <el-button size="small" @click="InTax(scope.row.id)" plain type="warning" v-loading.fullscreen.lock="fullscreenLoading" element-loading-text="打开中请稍后...">
            <el-icon><HomeFilled /></el-icon><span  > 进入税局</span>
          </el-button> -->
          <el-dropdown size="small">
            <qzf-button type="primary" size="small" plain style="margin-left:10px">
              更多菜单<el-icon class="el-icon--right"><arrow-down /></el-icon>
            </qzf-button>
            <template #dropdown>
              <el-dropdown-menu style="text-align:center">
                <!-- <el-dropdown-item>
                  <el-button v-if="scope.row.isHelp != 1" size="small" type="text" @click="empower(scope.row)">请求协助</el-button>
                </el-dropdown-item> -->
                <!-- <el-dropdown-item>
                  <el-button v-if="scope.row.isHelp == 1" size="small" @click="cancelEmpower(scope.row)" type="text">取消协助</el-button>
                </el-dropdown-item> -->
                <el-dropdown-item>
                  <el-button size="small" type="text" @click="createCompanyInfo(scope.row)">编辑</el-button>
                </el-dropdown-item>
                <!-- <el-dropdown-item>
                  <el-button size="small" type="text" @click="companyfp(scope.row)">分配</el-button>
                </el-dropdown-item> -->
                <el-dropdown-item>
                  <el-button size="small" type="text" @click="stopCompany(scope.row)">停报</el-button>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <div v-else>
          <el-tooltip v-if="scope.row.errLog" class="item" effect="dark" :content="scope.row.errLog" placement="top-start">
            <el-button v-if="scope.row.errLog" size="small" type="text" @click="gatherCJ(scope.row)">重新迁移</el-button>
          </el-tooltip>
          <el-button v-else size="small" type="text" @click="gatherCJ(scope.row)">迁移</el-button>
        </div>
      </template>
    </el-table-column>
    <el-table-column align="center" prop="address" label="操作" min-width="380" v-if="this.tabName == 'ninth'">
      <template #default="scope">
        <el-button @click="$goEtax(scope.row.id)" size="small" type="primary" plain>
          <el-icon><House /></el-icon><span  > 进入账簿</span>
        </el-button>
        <el-button size="small" @click="recoveryCom(scope.row)" plain type="success">
          <el-icon><RefreshLeft /></el-icon><span  > 还原到记账客户</span>
        </el-button>
        <!-- <el-button size="small" @click="recoveryAddCom(scope.row)" plain type="warning">还原到新增客户</el-button> -->
        <!-- 回收站删除 -->
        <el-button size="small" plain type="danger" @click="goRecycle(scope.row)" v-if="this.isAdmin == 1">
          <el-icon><Delete /></el-icon><span  > 删除</span>
        </el-button>
      </template>
    </el-table-column>
  </el-table>
  <!-- 分配 -->
  <companyFp ref="companyFp" code="bookkeeping" @success="getList"></companyFp>
  <div class="bottom_style">
    <div class="bottom_button" style="margin-top:20px">
      <div v-if="this.tabName == 'first' || this.tabName == 'second'|| this.tabName == 'third' || this.tabName == 'fourth' || this.tabName == 'fifth'">
        <!-- <el-button size="small" type="primary" plain v-if="this.tabName == 'first' && changeF" @click="settingMobile">
          <el-icon><Iphone /></el-icon><span  > 批量设置税局手机号</span>
        </el-button> -->
        <!-- <el-button size="small" type="success" plain v-if="this.tabName !== 'fifth'" @click="companyfpAll">
          <el-icon><User /></el-icon><span  > 批量分配</span>
        </el-button> -->
        <el-button size="small" type="warning" plain v-if="this.tabName == 'first' || this.tabName == 'second'" @click="automatic" :loading="noNeedLoading">
          <el-icon><Finished /></el-icon><span  > 批量设为无需初始化</span>
        </el-button>
        <el-button size="small" type="warning" plain v-if="this.tabName == 'fourth'">
          <el-icon><RefreshRight /></el-icon><span  > 批量设为未建账</span>
          
        </el-button>
        <el-button size="small" plain type="success" v-if="this.tabName == 'first'" @click="goAllBook()">
          <el-icon><RefreshRight /></el-icon><span  > 批量转移到记账客户</span>
        </el-button>
      </div>
      <!-- 记账客户 -->
      <div v-if="this.tabName == 'sixth' || this.tabName == 'seventh'|| this.tabName == 'eighth'">
        <!-- <el-button size="small" type="primary" plain v-if="this.tabName == 'seventh' || this.tabName == 'sixth' " @click="settingMobile">
          <el-icon><Iphone /></el-icon><span  > 批量设置税局手机号</span>
        </el-button> -->
        <!-- <el-button size="small" type="primary" plain v-if="this.tabName == 'seventh'" @click="nilReturn">
          <el-icon><Switch /></el-icon><span  > 设为零申报</span>
        </el-button>
        <el-button size="small" type="primary" plain v-if="this.tabName == 'sixth'" @click="removeNilReturn">
          <el-icon><Switch /></el-icon><span  > 移除零申报</span>
        </el-button> -->
        <!-- <el-button size="small" type="success" plain @click="companyfpAll">
          <el-icon><User /></el-icon><span  > 批量分配</span>
        </el-button> -->
        <el-button size="small" type="danger" plain @click="stopAllCompany">
          <el-icon><Warning /></el-icon> <span  > 批量停报</span>
        </el-button>
      </div>
      <div v-if="this.tabName == 'ninth'">
        <el-button size="small" type="success" plain @click="recoveryAllCom">
          <el-icon><RefreshLeft /></el-icon><span  > 批量还原到记账客户</span>
        </el-button>
        <el-button size="small" type="primary" plain @click="recoveryAllAddCom">批量还原到新增客户</el-button>
      </div>
      <div v-if="this.tabName == 'fifth'">
        <el-button size="small" type="primary" plain @click="recycleAllRecover">
          <el-icon><Refresh /></el-icon><span  > 批量还原</span>
        </el-button>
      </div>
    </div>
    <div class="pagination">
      <qzf-pagination v-show="userTotal>0" :total="userTotal" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>
  </div>
  <qzf-import type="daoruCustomer" ref="daoruCustomer" name="客户" @success="getList()" @handleSuccess="getListBegin"></qzf-import>
  <addCustomers @success="getList" ref="addCustomerCom"></addCustomers>
  <!-- 导入客户todo -->
  <el-dialog :close-on-click-modal="false" destroy-on-close title="提示" v-model="dialogTableVisibledaorukehu">
    <div style="margin-bottom:10px;">
      <span style="color: #67c23a;margin-right:20px">导入成功条数:{{callList.success_total}}</span>
      <span style="color:#F15A24;margin-right:20px">导入失败条数:{{callList.fail_total}}</span>
      <span style="color:#F15A24" v-if="callList.fail_reason">导入失败原因:{{callList.fail_reason}}</span>
    </div>
    <div style="height: 450px;overflow: auto;">
      <el-table :data="callList.fail_list" size="small" border>
        <el-table-column align="center" property="name" label="导入失败公司名称"></el-table-column>
        <el-table-column align="center" property="remark" label="原因"></el-table-column>
      </el-table>
    </div>
    <div style="margin: 15px 0; padding-bottom:10px">
      <el-button style="float: right;margin-left:10px" @click="dialogTableVisibledaorukehu = false" size="small" type="primary">关闭</el-button>
    </div>
  </el-dialog>

  <!-- 下载数据包 -->
  <el-dialog v-model="dialogTableVisible" title="下载数据包">
    <el-table :data="tableAuthOrgList" border :height="contentStyleObj2">
      <el-table-column property="name" label="公司名称" min-width="150" />
      <el-table-column property="startAccountPeriod" label="启用账期" min-width="120" />
      <el-table-column property="CurPeriod" label="当前账期" min-width="120"/>
      <el-table-column property="migrateFlag" label="迁移状态" min-width="120">
        <template #default="scope">
          <span style="color:#e6a23c" v-if="scope.row.migrateFlag == 0"> <i class="iconfont icon-gantanhao"></i> 未迁移</span>
          <span v-else style="color:green"> <i class="iconfont icon-duihao"></i> 已迁移</span>
        </template>
      </el-table-column>
      <el-table-column property="migrator" label="迁移人" min-width="150"/>
      <el-table-column align="center" prop="address" label="操作" min-width="120">
        <template #default="scope">
          <el-button @click="downLoadMigrate(scope.row)" size="small" type="primary" plain :disabled="scope.row.migrateFlag != 0">迁移</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>

   <!-- 领取教学包 -->
   <el-dialog v-model="receiveTableVisible" title="领取教学包">
    <el-table :data="tableReceiveList" border :height="contentStyleObj2">
      <el-table-column property="name" label="公司名称" min-width="150" />
      <el-table-column property="startAccountPeriod" label="启用账期" min-width="120" />
      <el-table-column property="curPeriod" label="当前账期" min-width="120"/>
      <el-table-column property="getFlag" label="领取状态" min-width="120">
        <template #default="scope">
          <span style="color:#e6a23c" v-if="scope.row.getFlag == 1"> <i class="iconfont icon-gantanhao"></i> 未领取</span>
          <span v-else style="color:green"> <i class="iconfont icon-duihao"></i> 已领取</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="address" label="操作" min-width="120">
        <template #default="scope">
          <el-button @click="makeSureReceivePackge(scope.row)" size="small" type="primary" plain :disabled="scope.row.getFlag != 1" :loading="scope.row.receiveLoading">领取</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>

  <!-- 迁移的弹框 -->
  <el-dialog v-model="chicePeriodVisible" :title="migrateCompanayName">
    <el-date-picker
      v-model="startAccountPeriod"
      type="month"
      value-format="YYYYMM"
      placeholder="启用账期"
    /> --
    <el-date-picker
      v-model="CurPeriod"
      type="month"
      value-format="YYYYMM"
      placeholder="当前帐期"
    />
    <template #footer>
      <span class="dialog-footer">
        <el-button type="success" @click="makeSureMigrate" :loading="this.migrateLoading">迁移</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { eaCompanyList, changeStatus, delCompany ,saveEaCompany ,comMemoryInfo , comMemorySave ,saveEaCompanySort} from "@/api/company"
import { getAuthOrgComList ,authCompanyOrgMove } from "@/api/org"
import { getPkgInfoList ,getCompanyInfoPkg } from "@/api/move"


// import { jumpCollection } from "@/api/taxTask"
import { companyMove } from "@/api/move"
import { automaticAccountCreation } from "@/api/build"
import { sendHelp,helpOver } from "@/api/help.js"
import companyFp  from "./companyFp";
import addCustomers from "./addCustomers";
import { sendTask, quitTask } from "@/api/collection"
import { assignCustomers } from "@/api/printSet"
import axios from 'axios'
import { loginTaxInfo } from "@/api/company"



export default {
  props: {
    tabName: String,
    listQuery: {}
  },
  data() {
    return {
      pageStatus: true,
      list:[],
      ids:undefined,
      datas:undefined,
      datacj:[],
      userTotal:0,
      selects:[],
      loading:false,  //表格加载
      loadingCJ: false,
      noNeedLoading:false,
      skipLoading:false,
      gatherId: [],
      dialogTableVisibledaorukehu:false,
      callList: [],
      visible:false,
      content:'',
      changeF:true,
      isAdmin:this.$store.getters['user/user'].isAdmin,
      noteContent:'',
      dialogConfirm:false,
      fullscreenLoading:false,
      contentStyleObj:{},
      contentStyleObj2:{},
      tableAuthOrgList:[],
      dialogTableVisible:false,
      startAccountPeriod:'',
      CurPeriod:'',
      chicePeriodVisible:false,
      migrateCompanayName:'',
      migrateId:'',
      migrateLoading:false,
      receiveTableVisible:false,
      tableReceiveList:[],
      userRoleName:this.$store.getters['user/user'].userRoleName,
      receiveLoading:false

    }
  },
  components: {
    companyFp,
    addCustomers
  },
  created(){
    this.getList()
    this.contentStyleObj= this.$getHeight(320)
    this.contentStyleObj2= this.$getHeight(350)

    this.initBus()
    if(localStorage.getItem("telShow") != undefined){
      this.changeF = localStorage.getItem("telShow") == 'true'
    }
  },
  activated(){
    this.pageStatus = true
  },
  deactivated(){
    this.pageStatus = false
  },
  methods:{
    changeShowTel(){
      localStorage.setItem("telShow",this.changeF)
    },
    initBus(){
      this.$bus.on("refreshTask", () => {
        if(!this.pageStatus){
          return
        }
        if(this.ids && this.ids.length != 0){
          return
        }
        if(this.list.length == 0){
          return
        }
        //刷新业务
        let status = false
        this.list.forEach(res => {
          if(res.setUpStatusCj == 3 || res.setUpStatusPp == 3) {
            status = true
          }
        })
        if(this.tabName == 'first' ||  this.tabName == 'fifth') {
          if(status == true) {
            this.getList()
          }
        }
      });
    },
    // 请求协助
    empower(row){
      this.$confirm('确认使用远程协助?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        sendHelp({comId:row.id}).then(res=>{
          if(res.data.msg == 'success'){
            this.$message({
              message: "请求成功",
              type: "success"
            });
            this.getList()
            this.$bus.emit('contentTable')
          }
        })
      })
    },
    // 取消协助
    cancelEmpower(row){
      this.$confirm('确认取消远程协助?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        helpOver({comId:row.id}).then(res=>{
          if(res.data.msg == 'success'){
            this.$message({
              message: "已取消",
              type: "success"
            });
            this.getList()
          }
        })
      })
    },
    handleCommand() {
      // this.$store.dispatch('commons/setParam', {addAccountsId: e.id})
      this.$store.dispatch('tagsView/delCachedViewByName', "addAccounts")
      this.$router.push({
        path: "../../company/addAccounts.vue",
        name: "addAccounts"
      });
    },
    //账套迁移
    handleMigrate() {
      this.$store.dispatch('tagsView/delCachedViewByName', "accountMigrate")
      this.$router.push({
        path: "../../company/accountMigrate.vue",
        name: "accountMigrate"
      });
    },
      //分配
    companyfp(row){
      // if(row.setUpStatusJz == 6){
      //   this.$qzfMessage("请先进行建账", 1)
      //   return
      // }
      let ids = row.id
      ids = [ids]
      this.$refs.companyFp.init(ids,row)
    },
    companyfpAll(){
      let statuss = false
      this.selects.map(v=>{
        if(v.setUpStatusJz == 6){
          statuss = true
        }
      })
      // if(statuss){
      //   this.$qzfMessage("请先进行建账", 1)
      //   return
      // }
      this.$refs.companyFp.init(this.ids)
    },
    handleSelectionChange(val) {
      this.selects = val;
      let ids = []
      val.map(v=>{
        ids.push(v.id)
      })
      this.ids = ids
      this.datas = val.map(v=>{
        return {comId: v.id}
      })
      this.datacj = val.map(v=>{
        return v.id
      })
      // this.$emit('gather',this.datacj)
    },
    gather() {
      if(!this.ids){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      let param = {
        taskName: 'tax-cj-jianzhang',
        comIds: this.datacj
      }
      this.loading = true
      sendTask(param).then(res => {
        this.loading = false
        if(res.data.msg == "success") {
          this.$qzfMessage('任务已发起',3)
          this.getList()
          this.$queueMessage(res.data.data.queue)
        }
      })
    },
    //记账客户采集
    gatherCJ(row) {
      this.loading = true
      this.$confirm('要发起迁移任务吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        companyMove({id: row.id}).then(res => {
          if(res.data.msg == 'success') {
            this.$message({
              type: 'success',
              message: '任务已发起!'
            });
            this.getList()
          }
          this.loading = false
        })
      }).catch(() => {
        this.loading = false
      });
    },
    //撤销
    cancelTask(){
      if(!this.ids){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      this.$confirm('确定撤销采集任务?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        quitTask({taskName:'tax-cj-jianzhang', comIds: this.ids}).then(res => {
          if(res.data.msg == 'success') {
            this.$cancelMsg(res.data.data.list)
            this.getList()
          }
        })
      })
    },
    skipCollection() {
      if(!this.ids){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      this.loading = true
      let param = {
        taskName: 'tax-cj-jump',
        comIds: this.datacj
      }
      sendTask(param).then(res => {
        this.loading = false
        if(res.data.msg == "success") {
          this.$qzfMessage('任务已发起',3)
          this.getList()
          this.$queueMessage(res.data.data.queue)
        }
      })
    },
    getList(){
      this.loading = true
      eaCompanyList(this.listQuery).then(res=>{
        this.loading = false
        this.list = res.data.data.list
        this.userTotal = res.data.data.total
      })
    },
    // 建账
    add_ea_subject_balance(e) {
      if(e.setUpStatusCj != '1' && e.setUpStatusCj != '2'){
          this.$message({
          message: '请先采集信息',
          type: 'warning'
          });
          return
      }
        // 新版跳转
      this.$store.dispatch('commons/setParam', {addCustomerComId: e.id})
      this.$store.dispatch("tagsView/delCachedViewByName", "subject_balance_uploadExcel_new");
      this.$router.push({
        path: "/company/subject_balance_uploadExcel_new",  
      });
    },
    setUpStatus1(e) {
      let num = e;
      if (num == 1) {
        return "已采集";
      } else if (num == 6) {
        return "未采集";
      } else if (num == 2) {
        return "跳过采集";
      } else if (num == 3) {
        return "排队中";
      } else if (num == 4) {
        return "采集失败";
      } else if (num == 5) {
        return "部分采集失败";
      } else if (num == 9) {
        return "采集中";
      }
    },
    setUpStatus2(e) {
      let num = e;
      if (num == 1) {
        return "已初始化";
      } else if (num == 6) {
        return "未初始化";
      }else if (num == 2) {
        return "无需初始化";
      }
    },
    setUpStatus3(e) {
      let num = e;
      if (num == 1) {
        return "初始化成功";
      } else if (num == 6) {
        return "未初始化";
      } else if (num == 2) {
        return "部分初始化失败";
      } else if (num == 3) {
        return "初始化中";
      } else if (num == 4) {
        return "初始化失败";
      }
    },
    handleUpdate(e){
      this.$store.dispatch('commons/setParam', {editCustomerComId: e})
      this.$router.push({
        path: "/company/comInfo",
        name:"editCustomer"
      });
    },
      // 批量无需建账
    automatic(){
      let statuss = false
      this.selects.map(v=>{
        if(v.setUpStatusCj != 1 && v.setUpStatusCj != 2){
          statuss = true
        }
      })
      if(statuss){
        this.$qzfMessage("请先进行采集", 1)
        return
      }
      if(this.datas){
        let param = {
          params:this.datas
        }
        this.noNeedLoading = true
        automaticAccountCreation(param).then(res=>{
          this.noNeedLoading = false
            if(res.data.msg == "success"){
              this.$qzfMessage("设置成功") 
              this.getList()
            }
        })
      }else{
        this.$qzfMessage("请选择公司", 1) 
      }
    },
    createCompanyInfo(e) {
      this.$store.dispatch('commons/setParam', {editCustomerComId: e})
      this.$router.push({
        path: "/company/editCustomer",
        name: "editCustomer"
      });
    },
    // skipCollection(){
    //   if(!this.ids){
    //     this.$qzfMessage("请选择公司", 1)
    //     return
    //   }
    //   jumpCollection(this.datas).then(res=>{
    //     if(res.data.msg == "success"){
    //       this.getList()
    //       this.$bus.emit('loadingClose')
    //       this.$qzfMessage("跳过采集成功")
    //     }else{
    //       this.$bus.emit('loadingClose')
    //       this.$qzfMessage("跳过采集失败",2)
    //     }
    //   })
    // },
    stopCompany(row){
      let that = this
      this.$prompt('请输入停报原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        if(!value){
          that.$qzfMessage("请输入停报原因", 1)
          return
        }
        row.status = 3
        row.remark = value
        changeStatus([row]).then(res=>{
          if(res.data.msg == "success"){
            that.$qzfMessage("设置成功")
            that.getList()
            that.$bus.emit('stopReportingUpdate')
          }
        })
      })


      
    },
    stopAllCompany(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司", 1) 
        return
      }
      this.selects.map(v=>{
        v.status = 3
      })
      changeStatus(this.selects).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("设置成功")
          this.getList()
          this.$bus.emit('stopReportingUpdate')
        }
      })
    },
    recoveryCom(row){
      row.status = 2
      changeStatus([row]).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("设置成功")
          this.getList()
          this.$bus.emit('addCustomerUpdate')
        }
      })
    },
    recoveryAllCom(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司",1)
        return
      }
      this.selects.map(v=>{
        v.status = 2
      })
      changeStatus(this.selects).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("设置成功")
          this.getList()
          this.$bus.emit('addCustomerUpdate')
        }
      })
    },
    recoveryAddCom(row){
      row.status = 1
      changeStatus([row]).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("设置成功")
          this.getList()
          this.$bus.emit('addCustomerUpdate')
        }
      })
    },
    recoveryAllAddCom(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司",1)
        return
      }
      this.selects.map(v=>{
        v.status = 1
      })
      changeStatus(this.selects).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("设置成功")
          this.getList()
          this.$bus.emit('addCustomerUpdate')
        }
      })
    },
    nilReturn(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司",1)
        return
      }
      this.selects.map(v=>{
        v.accountingType = 1
      })
      changeStatus(this.selects).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("设置成功")
          this.getList()
        }
      })
    },
    removeNilReturn(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司",1)
        return
      }
      this.selects.map(v=>{
        v.accountingType = 2
      })
      changeStatus(this.selects).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("设置成功")
          this.getList()
        }
      })
    },
    goRecycle(row){
      row.status = 9
      this.$confirm('确定要删除该公司吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        changeStatus([row]).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("删除成功")
            this.getList()
          }
        })
      });
    },

    goRecycles(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司",1)
        return
      }
      this.selects.map(v=>{
        v.status = 9
      })
      this.$confirm('确定要批量彻底删除这些公司吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        changeStatus(this.selects).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("删除成功")
            this.getList()
          }
        })
      });
    },
    delCustomer(row){
      row.status = 9
      this.$confirm('确定要彻底删除该公司吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delCompany([row]).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("删除成功")
            this.getList()
          }
        })
      });
    },
    delAll(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司",1)
        return
      }
      this.selects.map(v=>{
        v.status = 9
      })
      this.$confirm('确定要批量彻底删除这些公司吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        delCompany(this.selects).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("删除成功")
            this.getList()
          }
        })
      });
    },
    settingMobile(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司",1)
        return
      }
      this.$prompt('请输入手机号', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue:'',
        // inputPattern:/^[1][3,4,5,6,7,8,9][0-9]{9}$/,
        // inputErrorMessage: '手机号格式不正确',
      }).then(({ value }) => {
        this.selects.map(v=>{
          v.mobileRec = value
        })
        saveEaCompany({list:this.selects}).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("设置成功")
            this.getList()
          }
        })
      })
    },
    Tobreak(val){
      return val.replace(/[\r\n]/g, '<br/>')
    },
    recycleRecover(row){
      row.status = 3
      this.$confirm('确定要还原该公司吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        changeStatus([row]).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("还原成功")
            this.getList()
          }
        })
      });
    },
    recycleAllRecover(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司",1)
        return
      }
      this.selects.map(v=>{
        v.status = 3
      })
      this.$confirm('确定要还原该些公司吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        changeStatus(this.selects).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("还原成功")
            this.getList()
          }
        })
      });
    },
    getListBegin(e){
      this.callList = e
      this.dialogTableVisibledaorukehu = true
      this.getList()
    },
    addCustomerCom(){
      this.$refs.addCustomerCom.handleCreate()
    },
    daoruCustomer(){
      this.$refs.daoruCustomer.handRedImport()
    },
    smallNotes(id){
      this.visible = true
      this.dialogConfirm = false
      comMemoryInfo({comId:id}).then(res=>{
        this.noteContent = res.data.data.content
      })
    },
    editNote(row,e){
      if(e == 2){
        this.dialogConfirm = true
      }
      comMemorySave({comId:row.id,content:this.noteContent}).then(res=>{
        row.content = this.noteContent
        if(res.data.msg == "success"){
          row.visible = false
          this.$qzfMessage("保存成功")
        }
      })
    },
    editNote1(row){
      if(this.dialogConfirm){
        return
      }
      comMemorySave({comId:row.id,content:this.noteContent}).then(res=>{
        row.content = this.noteContent
        if(res.data.msg == "success"){
          row.visible = false
          this.$qzfMessage("保存成功")
        }
      })
    },
    goBook(row){
      if(row.setUpStatusJz == 6){
        this.$qzfMessage("请先进行建账", 1)
        return
      }
      row.status = 2
      this.$confirm('确定要转到记账客户吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        changeStatus([row]).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("设置成功")
            this.getList()
          }
        })
      });
    },
    goAllBook(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司", 1) 
        return
      }
      let statuss = false
      this.selects.map(v=>{
        if(v.setUpStatusJz == 6){
          statuss = true
        }
      })
      if(statuss){
        this.$qzfMessage("请先进行建账", 1)
        return
      }
      this.selects.map(v=>{
        v.status = 2
      })
      this.$confirm('确定要转到记账客户吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        changeStatus(this.selects).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("设置成功")
            this.getList()
          }
        })
      });
    },
     // 公司编号
    saveSort(row){
      this.$prompt('请输入编号', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        saveEaCompanySort({id: row.id,sortCom: value}).then(res=>{
          this.getList()
          this.$message({
            type: 'success',
            message: '保存成功'
          });
        })
      })
    },
    sortNumber(e){
      if(!e){
        return "默认"
      }else{
        return e
      }
    },
    // 表格排序
    sortChange(data) {
      if(data.order == "descending"){
        this.listQuery.orderBy = "ea_company.sort_com desc,ea_company.id desc"
      }else{
        this.listQuery.orderBy = "ea_company.sort_com,ea_company.id desc"
      }
      this.getList()
    },
    customerExport(){
      let param = {
        query:this.listQuery
      }
      assignCustomers(param).then(res => {
        if (res.data.msg == "success") {
          window.open(res.data.data.url); 
        }
      })
    },
    InTax(id){
      // this.fullscreenLoading = true
      // this.$qzfMessage('正在登录税局，预计1-2分钟',1);
      this.$alert('正在登录税局，预计1-2分钟', '进入税局提示', {
        confirmButtonText: '确定',
      });
      let that = this
      loginTaxInfo({id:id}).then(res=>{
        let param = res.data.data.info
        axios.post('http://localhost:9111/loginTax/autoLoginTax',param).then(res=>{
          // that.fullscreenLoading = false
          if(res.data.msg != "success"){
            that.$alert(res.data.msg, '提示', {
              confirmButtonText: '确定',
            });
          } 

        }).catch(function (e) {
          that.$alert('请先安装客户端', '提示', {
            confirmButtonText: '确定',
          });
          // that.fullscreenLoading = false
          return
        });

      })
    },
    // 下载数据包
    downLoadPack(){
      getAuthOrgComList({}).then(res => {
        this.dialogTableVisible = true
        this.tableAuthOrgList = res.data.data
      })
    },
    downLoadMigrate(row){
      this.migrateCompanayName = row.name
      this.startAccountPeriod = row.startAccountPeriod
      this.CurPeriod = row.CurPeriod
      this.migrateId = row.id
      this.chicePeriodVisible = true
    },
    makeSureMigrate(){
      this.migrateLoading = true
      let param = {
        startAccountPeriod:this.startAccountPeriod,
        CurPeriod:this.CurPeriod,
        name:this.migrateCompanayName,
        id:this.migrateId
      }
      authCompanyOrgMove(param).then(res => {
        this.migrateLoading = false
        if(res.data.msg == "success"){
          this.downLoadPack()
          this.$qzfMessage("迁移成功")
          this.getList()
          this.chicePeriodVisible = false
          this.dialogTableVisible = false
        }
      })
    },
    // 教学包列表
    receivePackge(){
      getPkgInfoList({}).then(res => {
        this.receiveTableVisible = true
        this.tableReceiveList = res.data.data
        this.tableReceiveList.map(v => {
          v.receiveLoading = false
        })
      })
    },
    // 领取教学包
    makeSureReceivePackge(row){
      row.receiveLoading = true
      getCompanyInfoPkg({id:row.id}).then(res => {
        row.receiveLoading = false
        if(res.data.msg == "success"){
          this.$qzfMessage("领取成功")
          this.receiveTableVisible = false
          this.receiveTableVisible = true
          this.receivePackge()
          this.getList()
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.small_cell {
  div{
    padding-left: 2px !important;
    padding-right: 0 !important;
  }
}
.item_icon{
  display: inline-block;
  width: 58%;
  padding: 1px 3px;
  padding-right: 10px;
  
  cursor: pointer;
  i{
    display: inline-block;
    line-height: 28px;
    font-size: 16px;
  }
  .iconfont icon-cuowu{
    color: #f56c6c;
  }
  .iconfont icon-duihao{
    color: #67c23a;
  }
  .el-icon-circle-check{
    color: #67c23a;
  }
  .iconfont icon-info{
    color: #409eff
  }
  .iconfont icon-cuowu{
    color: #e6a23c;
  }
  .iconfont icon-cuowu-outline{
    color: #e6a23c;
  }

  p{
    display: inline-block;
    font-size: 14px;
    line-height: 28px;
    color: #333;
  }
}
.right_box2 {
  position: absolute;
  right: 19px;
  top: 11px;
  left: 362px;
}
.right_box3 {
  position: absolute;
  right: 19px;
  top: 15px;
  left: 350px;
}
.right_box {
  position: absolute;
  right: 19px;
  top: 13px;
}
.right_box1 {
  float: right;
  width: 30%;
  text-align: right;
  margin-bottom: 5px;
}

</style>
<style scoped lang="scss">
 
</style>